




















































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import Task from '@/models/experiment/Task';
import TaskService from '@/services/TaskService';
import GoogleFormsTask from '@/models/experiment/GoogleFormsTask';

@Component
export default class EditGoogleFormsTask extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected task = new GoogleFormsTask('', '', '', 0, 0, false, [], -1, '', '', '');
  @Prop({ required: true })
  protected taskId!: number;

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.loadTask();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected loadTask(): void {
    this.setLoading(true);
    TaskService.getGoogleFormsTaskById(this.taskId)
      .then((result) => {
        this.setLoading(false);
        this.task = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load task. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin/edit/' + this.task.experiment });
  }

  protected saveTask(): void {
    this.setLoading(true);
    TaskService.saveGoogleFormsTask(this.task)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/experiment/admin/edit/' + this.task.experiment });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add task. ' + backendError.response.data.message, 'danger');
        }
      });
  }
}
