


























































import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Submission from '@/models/experiment/Submission';
import SubmissionService from '@/services/SubmissionService';
import { Prop } from 'vue-property-decorator';

@Component
export default class SubmissionsList extends mixins(Toasts) {
  protected submissions: Submission[] = [];
  protected pageSize = 10;
  protected currentPageNo = 1;
  @Prop({ required: true })
  protected taskId!: number;
  @Prop({ required: true })
  protected experimentId!: number;

  mounted(): void {
    this.getAllSubmissions();
  }

  protected getAllSubmissions(): void {
    SubmissionService.getAllSubmissionsOfTask(this.taskId)
      .then((res) => {
        this.submissions = res;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load submissions. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected editSubmission(submission: Submission) {
    this.$router.push('/submission/admin/edit/' + this.experimentId + '/' + this.taskId + '/' + submission.id);
  }

  protected deleteSubmission(submission: Submission): void {
    if (confirm('Do you really want to delete the submission of ' + submission.username + '?')) {
      this.setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      SubmissionService.deleteSubmission(submission.id!)
        .then(() => {
          this.setLoading(false);
          this.$router.go(0);
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to delete',
              'Could not delete submission. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    }
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected submissionFields = [
    {
      key: 'username',
      label: 'Username',
      sortable: true,
    },
    {
      key: 'completed',
      label: 'Completed',
      sortable: true,
    },
    {
      key: 'points',
      label: 'Points',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
  ];
}
