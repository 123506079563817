import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Submission {
  @JsonProperty('id')
  private _id?: number;
  @JsonProperty('username')
  private _username: string;
  @JsonProperty('completed')
  private _completed: boolean;
  @JsonProperty('points')
  private _points?: number;
  @JsonProperty('taskId')
  private _taskId: number;

  constructor(username: string, completed: boolean, taskId: number) {
    this._username = username;
    this._completed = completed;
    this._taskId = taskId;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get completed(): boolean {
    return this._completed;
  }

  set completed(value: boolean) {
    this._completed = value;
  }

  get points(): number | undefined {
    return this._points;
  }

  set points(value: number | undefined) {
    this._points = value;
  }

  get taskId(): number {
    return this._taskId;
  }

  set taskId(value: number) {
    this._taskId = value;
  }
}
