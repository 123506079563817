import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import { AxiosResponse } from 'axios';
import Submission from '@/models/experiment/Submission';

export default class SubmissionService {
  private static submissionURL = '/submission';

  public static saveSubmission(submission: Submission): Promise<Submission> {
    return MainService.post(this.submissionURL, JSON.stringify(serialize(submission))).then((response) => {
      return deserialize(response.data, Submission);
    });
  }

  public static getSubmissionById(submissionId: number): Promise<Submission> {
    return MainService.get(this.submissionURL + '/' + submissionId).then((response) => {
      return deserialize(response.data, Submission);
    });
  }

  public static getAllSubmissionsOfTask(taskId: number): Promise<Submission[]> {
    return MainService.get(this.submissionURL + '/task/' + taskId).then((response) => {
      return response.data.map((item) => deserialize(item, Submission));
    });
  }

  public static getAllSubmissionsOfExperiment(experimentId: number): Promise<Submission[]> {
    return MainService.get(this.submissionURL + '/experiment/' + experimentId).then((response) => {
      return response.data.map((item) => deserialize(item, Submission));
    });
  }

  public static getAllSubmissionsOfTaskForUser(taskId: number): Promise<Submission[]> {
    return MainService.get(this.submissionURL + '/task/user/' + taskId).then((response) => {
      return response.data.map((item) => deserialize(item, Submission));
    });
  }

  public static deleteSubmission(submissionId: number): Promise<AxiosResponse> {
    return MainService.delete(this.submissionURL + '/' + submissionId);
  }
}
