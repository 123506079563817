






























import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import EditExperiment from '@/views/experiment/EditExperiment.vue';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import TaskList from '@/components/experiment/TaskList.vue';
import AddTask from '@/components/experiment/AddTask.vue';
import AddGoogleFormsTask from '@/components/experiment/AddGoogleFormsTask.vue';
import AddReviewTask from '@/components/experiment/AddReviewTask.vue';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';
import TaskService from '@/services/TaskService';
import EditTask from '@/components/experiment/EditTask.vue';
import EditReviewTask from '@/components/experiment/EditReviewTask.vue';
import EditGoogleFormsTask from '@/components/experiment/EditGoogleFormsTask.vue';
import SubmissionsList from '@/components/experiment/SubmissionsList.vue';

@Component({
  components: {
    SubmissionsList,
    EditGoogleFormsTask,
    EditReviewTask,
    EditTask,
    AddReviewTask,
    AddGoogleFormsTask,
    AddTask,
    EditExperiment,
    TaskList,
  },
})
export default class EditTaskView extends mixins(Toasts) {
  protected experimentId = -1;
  protected taskId = -1;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected taskType = '';

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.experimentId = Number.parseInt(this.$route.params.experimentId);
      this.taskId = Number.parseInt(this.$route.params.taskId);
      if (Number.isNaN(this.experimentId) || Number.isNaN(this.taskId)) {
        this.$router.replace({ path: '/experiment/admin' });
        return;
      }
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiments', to: '/experiment/admin' },
        { text: 'Experiment Details', to: '/experiment/admin/edit/' + this.experimentId },
        { text: 'Edit Task', active: true },
      ];
      this.getTaskType();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected getTaskType(): void {
    this.setLoading(true);
    TaskService.getTaskType(this.taskId)
      .then((result) => {
        this.setLoading(false);
        this.taskType = TaskTypeEnum[result];
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load task. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
